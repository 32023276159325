import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Prueba2 from "../Prueba/Prueba2";
import Footer from "../Footer/Footer";
import "./ProductoExihibicion.css";

import nitrato_de_amonio from "../../assets/img/img_banner_producto/1_Nitrato_De_Amonio.jpg";
import nittrato_de_potasio from "../../assets/img/img_banner_producto/2_Nitrato_De_Potasio.jpg";
import map_12_61_0 from "../../assets/img/img_banner_producto/3_map_12_61_0.jpg";
import sulgato_de_potasio from "../../assets/img/img_banner_producto/4_Sulfato_De_Potasio.jpg";
import acido_fosforico from "../../assets/img/img_banner_producto/5_Ácido_Fosfórico.jpg";
import mkp_0_52_34 from "../../assets/img/img_banner_producto/6_MKP_0_52_34.jpg";
import nitrato_de_calcio from "../../assets/img/img_banner_producto/7_Nitrato_De_Calcio.jpg";
import muriato_de_potasio from "../../assets/img/img_banner_producto/8_Muriato_De_Potasio.jpg";
import ureal from "../../assets/img/img_banner_producto/9_Urea.jpg";
import sulfato_de_magnesio_heptahidratado from "../../assets/img/img_banner_producto/10_Sulfato_De_Magnesio_Heptahidratado.jpg";
import acido_borico from "../../assets/img/img_banner_producto/11_Ácido_Bórico.jpg";
import aminoacidos_80 from "../../assets/img/img_banner_producto/12_Aminoácidos_80.jpg";
import sulfato_de_amonio from "../../assets/img/img_banner_producto/13_Sulfato_De_Amonio.jpg";
import octoborato_de_sodio from "../../assets/img/img_banner_producto/14_Octoborato_De_Sodio.jpg";
import acido_nitrico from "../../assets/img/img_banner_producto/15_Ácido_Nitrico.jpg";
import sulfato_de_cobre from "../../assets/img/img_banner_producto/16_Sulfato_De_Cobre.jpg";
import molibdato_de_sodio from "../../assets/img/img_banner_producto/17_Molibdato_De_Sodio.jpg";
import nitrato_de_magnesio from "../../assets/img/Productos/18_Nitrato_de_Magnesio.jpg";
import sulfato_de_zinc_heptahidratado from "../../assets/img/img_banner_producto/19_Sulfato_De_Zinc_Heptahidratado.jpg";
import sulfato_de_manganeso from "../../assets/img/img_banner_producto/20_Sulfato_De_Manganeso.jpg";
import sulfato_ferroso from "../../assets/img/img_banner_producto/21_Sulfato_Ferroso.jpg";
import proalgen_img from "./../../assets/img/img_banner_producto/23_proalgen.jpg";
import humonox_a_img from "./../../assets/img/img_banner_producto/22_huminox_a.jpg";
import fullharvest from "./../../assets/img/img_banner_producto/24_full_harverst.jpg";

import img_8331 from "./../../assets/img/IMG_8331.png";
import img_7297 from "./../../assets/img/IMG_7297.png";

import huminox_a_logo_gris from "./../../assets/img/logos/22_huminox_a_logo_gris.png";
import proalgen_logo_gris from "./../../assets/img/logos/23_proalgen_logo_gris.png";
import fullharvest_logo_gris from "./../../assets/img/logos/24_full_harverst_logo_gris.png";

import Productos from "../Productos/Productos";

// presentaciones
import presentacion_saco from "./../../assets/img/icons/presentacion_saco.png";
import presentacion_tonel from "./../../assets/img/icons/presentacion_tonel.png";
import presentacion_galon from "./../../assets/img/icons/presentacion_caneca.png";
import { Fade } from "react-awesome-reveal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { t } from "i18next";
import { useLanguage } from "../../Context/LanguageContext";
export default function ProductoExhibicion() {
  let { nombre_producto } = useParams();
  const { language } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const productos = [
    //1
    {
      id: 1,
      nombre: t("ACID_BORIC"),
      image: acido_borico,
      desc: t("DESCRIPCION_ACID_BORIC"),
      formula: "H₃BO₃",
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("PUREZA"),
          especificaciones: "≥ 99.80%",
        },
        {
          id: 2,
          componente: t("BORO_B2O3"),
          especificaciones: "≥ 56.25%",
        },
        {
          id: 3,
          componente: t("BORO_B"),
          especificaciones: "≥ 17.40%",
        },
        {
          id: 4,
          componente: t("PH_SOL_5"),
          especificaciones: "3.80",
        },
        {
          id: 5,
          componente: t("APARIENCIA"),
          especificaciones: t("APARIENCIA_SPEC"),
        },
      ],
    },
    //2
    {
      id: 2,
      nombre: t("NOMBRE_ACIDO_FOSFORICO"),
      image: acido_fosforico,
      desc: t("DESC_ACIDO_FOSFORICO"),
      formula: t("FORMULA_ACIDO_FOSFORICO"),
      presentaciones: [
        {
          presentacionImg: presentacion_galon,
          presentacionNombre: "35 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA"),
          especificaciones: "≥ 85.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_FOSFORO_P2O5"),
          especificaciones: "≥ 61.60%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_FOSFORO_P"),
          especificaciones: "≥ 27.00%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_DENSIDAD"),
          especificaciones: "≥ 1.68 g/ml",
        },
        {
          id: 5,
          componente: t("COMPONENTE_PH"),
          especificaciones: "≤ 3.00",
        },
        {
          id: 6,
          componente: t("COMPONENTE_APARIENCIA"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA"),
        },
      ],
    },
    //3
    {
      id: 3,
      nombre: t("NOMBRE_ACIDO_NITRICO"),
      image: acido_nitrico,
      desc: t("DESC_ACIDO_NITRICO"),
      formula: t("FORMULA_ACIDO_NITRICO"),
      presentaciones: [
        {
          presentacionImg: presentacion_tonel,
          presentacionNombre: "280 KG",
        },
        {
          presentacionImg: presentacion_tonel,
          presentacionNombre: "290 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_AN"),
          especificaciones: "≥ 68.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_DENSIDAD_AN"),
          especificaciones: "1.41 g/ml",
        },
        {
          id: 3,
          componente: t("COMPONENTE_APARIENCIA_AN"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_AN"),
        },
      ],
    },
    //4
    {
      id: 4,
      nombre: t("NOMBRE_AMINOACIDOS_80"),
      image: aminoacidos_80,
      desc: t("DESC_AMINOACIDOS_80"),
      formula: "-",
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "0.5 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_AMINOACIDOS_LIBRES"),
          especificaciones: "80.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_ESTADO_FISICO"),
          especificaciones: t("ESPECIFICACIONES_ESTADO_FISICO"),
        },
        {
          id: 3,
          componente: t("COMPONENTE_DENSIDAD_A80"),
          especificaciones: "0.60 g/ml",
        },
        {
          id: 4,
          componente: t("COMPONENTE_SOLUBILIDAD"),
          especificaciones: t("ESPECIFICACIONES_SOLUBILIDAD"),
        },
      ],
    },
    //5
    {
      id: 5,
      nombre: t("NOMBRE_MAP_12_61_0"),
      image: map_12_61_0,
      desc: t("DESC_MAP_12_61_0"),
      formula: t("FORMULA_MAP_12_61_0"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_MAP"),
          especificaciones: "≥ 98.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_NITROGENO_AMONIACAL"),
          especificaciones: "12.00%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_FOSFORO"),
          especificaciones: "61.00%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_PH_MAP"),
          especificaciones: "4.50",
        },
        {
          id: 4,
          componente: t("COMPONENTE_HUMEDAD"),
          especificaciones: "≤ 0.20%",
        },
      ],
    },
    //6
    {
      id: 6,
      nombre: t("NOMBRE_MKP_0_52_34"),
      image: mkp_0_52_34,
      desc: t("DESC_MKP_0_52_34"),
      formula: t("FORMULA_MKP_0_52_34"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_MKP"),
          especificaciones: "≥ 99.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_FOSFORO_MKP"),
          especificaciones: "52.00%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_POTASIO"),
          especificaciones: "34.00%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_PH_1_SOLUCION"),
          especificaciones: "4.60",
        },
        {
          id: 5,
          componente: t("COMPONENTE_APARICIENCIA"),
          especificaciones: t("ESPECIFICACIONES_APARICIENCIA"),
        },
      ],
    },
    //7
    {
      id: 7,
      nombre: t("NOMBRE_MOLIBDATO_DE_SODIO_DIHIDRATADO"),
      image: molibdato_de_sodio,
      desc: t("DESC_MOLIBDATO_DE_SODIO_DIHIDRATADO"),
      formula: t("FORMULA_MOLIBDATO_DE_SODIO_DIHIDRATADO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "1 KG",
        },
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_MSD"),
          especificaciones: "≥ 99.50%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_MOLIBDENO"),
          especificaciones: "≥ 9.45%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_APARICIENCIA_MSD"),
          especificaciones: t("ESPECIFICACIONES_APARICIENCIA_MSD"),
        },
        {
          id: 4,
          componente: t("COMPONENTE_COLOR"),
          especificaciones: t("ESPECIFICACIONES_COLOR"),
        },
        {
          id: 5,
          componente: t("COMPONENTE_SOLUBILIDAD_EN_AGUA"),
          especificaciones: "840 g/L",
        },
        {
          id: 6,
          componente: t("COMPONENTE_DENSIDAD_MSD"),
          especificaciones: "2.71 g/ml",
        },
      ],
    },
    //8
    {
      id: 8,
      nombre: t("NOMBRE_MURIATO_DE_POTASIO"),
      image: muriato_de_potasio,
      desc: t("DESC_MURIATO_DE_POTASIO"),
      formula: t("FORMULA_MURIATO_DE_POTASIO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "45.36 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_MP"),
          especificaciones: "≥ 98.20%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_OXIDO_DE_POTASIO"),
          especificaciones: "≥ 62.00%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_POTASIO_MP"),
          especificaciones: "≥ 51.50%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_SODIO"),
          especificaciones: "≤ 0.59%",
        },
        {
          id: 5,
          componente: t("COMPONENTE_MAGNESIO"),
          especificaciones: "≤ 0.005%",
        },
        {
          id: 6,
          componente: t("COMPONENTE_CALCIO"),
          especificaciones: "≤ 0.014%",
        },
        {
          id: 7,
          componente: t("COMPONENTE_SULFATO"),
          especificaciones: "≤ 0.033%",
        },
      ],
    },
    //9
    {
      id: 9,
      nombre: t("NOMBRE_NITRATO_DE_AMONIO"),
      image: nitrato_de_amonio,
      desc: t("DESC_NITRATO_DE_AMONIO"),
      formula: t("FORMULA_NITRATO_DE_AMONIO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "45.36 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_NITROGENO"),
          especificaciones: "34.40%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_NITROGENO_AMONIACAL_NA"),
          especificaciones: "50.00%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_NITROGENO_NITRICO"),
          especificaciones: "50.00%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_FORMA"),
          especificaciones: t("ESPECIFICACIONES_FORMA"),
        },
        {
          id: 5,
          componente: t("COMPONENTE_GRANULOMETRIA"),
          especificaciones: "2-4 mm",
        },
      ],
    },
    //10
    {
      id: 10,
      nombre: t("NOMBRE_NITRATO_DE_CALCIO"),
      image: nitrato_de_calcio,
      desc: t("DESC_NITRATO_DE_CALCIO"),
      formula: t("FORMULA_NITRATO_DE_CALCIO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_NITROGENO_TOTAL"),
          especificaciones: "15.50%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_NITROGENO_NITRICO_NN"),
          especificaciones: "15.50%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_NITROGENO_AMONIACAL_NN"),
          especificaciones: "1.00%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_OXIDO_CALCIO"),
          especificaciones: "27.00%",
        },
      ],
    },
    //11
    {
      id: 11,
      nombre: t("NOMBRE_NITRATO_DE_MAGNESIO"),
      image: nitrato_de_magnesio,
      desc: t("DESC_NITRATO_DE_MAGNESIO"),
      formula: t("FORMULA_NITRATO_DE_MAGNESIO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_NM"),
          especificaciones: "≥ 98.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_ESTADO_FISICO_SF"),
          especificaciones: t("ESPECIFICACIONES_ESTADO_FISICO_SF"),
        },
        {
          id: 3,
          componente: t("COMPONENTE_COLOR_SF"),
          especificaciones: t("ESPECIFICACIONES_COLOR_SF"),
        },
        {
          id: 4,
          componente: t("COMPONENTE_OLOR"),
          especificaciones: t("ESPECIFICACIONES_OLOR"),
        },
        {
          id: 5,
          componente: t("COMPONENTE_DENSIDAD_SF"),
          especificaciones: "1.46 g/cm3",
        },
        {
          id: 6,
          componente: t("COMPONENTE_SOLUBILIDAD_SF"),
          especificaciones: t("ESPECIFICACIONES_SOLUBILIDAD_SF"),
        },
      ],
    },
    //12
    {
      id: 12,
      nombre: t("NOMBRE_NITRATO_DE_POTASIO"),
      image: nittrato_de_potasio,
      desc: t("DESC_NITRATO_DE_POTASIO"),
      formula: t("FORMULA_NITRATO_DE_POTASIO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_NITROGENO_NITRICO_NP"),
          especificaciones: "13.50%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_POTASIO_K2O"),
          especificaciones: "46.00%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_GRANULOMETRIA_NP"),
          especificaciones: "< 0.50 mm",
        },
        {
          id: 4,
          componente: t("COMPONENTE_DENSIDAD_NP"),
          especificaciones: "1100 Kg/m3",
        },
      ],
    },
    //13
    {
      id: 13,
      nombre: t("NOMBRE_OCTOBORATO_DE_SODIO"),
      image: octoborato_de_sodio,
      desc: t("DESC_OCTOBORATO_DE_SODIO"),
      formula: t("FORMULA_OCTOBORATO_DE_SODIO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_OS"),
          especificaciones: "≥ 99.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_BORO_B2O3"),
          especificaciones: "67.31%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_BORO_B"),
          especificaciones: "15.05%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_DENSIDAD_OS"),
          especificaciones: "500 Kg/m3",
        },
        {
          id: 5,
          componente: t("COMPONENTE_PH_SOLUCION_5"),
          especificaciones: "7.07",
        },
      ],
    },
    //14
    {
      id: 14,
      nombre: t("NOMBRE_SULFATO_DE_AMONIO"),
      image: sulfato_de_amonio,
      desc: t("DESC_SULFATO_DE_AMONIO"),
      formula: t("FORMULA_SULFATO_DE_AMONIO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "45.36 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_NITROGENO_AMONIACAL_NAM"),
          especificaciones: "21.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_AZUFRE_S"),
          especificaciones: "24.00%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_APARIENCIA_NAM"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_NAM"),
        },
        {
          id: 4,
          componente: t("COMPONENTE_COLOR_NAM"),
          especificaciones: t("ESPECIFICACIONES_COLOR_NAM"),
        },
        {
          id: 5,
          componente: t("COMPONENTE_DENSIDAD_NAM"),
          especificaciones: "962 Kg/m3",
        },
        {
          id: 6,
          componente: t("COMPONENTE_SOLUBILIDAD_NAM"),
          especificaciones: t("ESPECIFICACIONES_SOLUBILIDAD_NAM"),
        },
      ],
    },
    //15
    {
      id: 15,
      nombre: t("NOMBRE_SULFATO_DE_COBRE"),
      image: sulfato_de_cobre,
      desc: t("DESC_SULFATO_DE_COBRE"),
      formula: t("FORMULA_SULFATO_DE_COBRE"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_SC"),
          especificaciones: "≥ 98.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_COBRE_CU"),
          especificaciones: "≥ 25.00%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_APARIENCIA_CU"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_CU"),
        },
        {
          id: 4,
          componente: t("COMPONENTE_COLOR_CU"),
          especificaciones: t("ESPECIFICACIONES_COLOR_CU"),
        },
        {
          id: 5,
          componente: t("COMPONENTE_GRANULOMETRIA_CU"),
          especificaciones: "< 1.00 mm",
        },
        {
          id: 6,
          componente: t("COMPONENTE_PH_SOLUCION_5_CU"),
          especificaciones: "4.00",
        },
      ],
    },
    //16
    {
      id: 16,
      nombre: t("NOMBRE_SULFATO_DE_MAGNESIO_HEPTAHIDRATADO"),
      image: sulfato_de_magnesio_heptahidratado,
      desc: t("DESC_SULFATO_DE_MAGNESIO_HEPTAHIDRATADO"),
      formula: t("FORMULA_SULFATO_DE_MAGNESIO_HEPTAHIDRATADO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_SMH"),
          especificaciones: "≥ 99%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_SULFATO_DE_MAGNESIO"),
          especificaciones: "≥ 48.50%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_OXIDO_DE_MAGNESIO"),
          especificaciones: "≥ 16%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_MAGNESIO_SMH"),
          especificaciones: "≥ 9.80%",
        },
        {
          id: 5,
          componente: t("COMPONENTE_AZUFRE"),
          especificaciones: "≥ 12.50%",
        },
        {
          id: 6,
          componente: t("COMPONENTE_APARIENCIA_SMH"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_SMH"),
        },
        {
          id: 7,
          componente: t("COMPONENTE_COLOR_SMH"),
          especificaciones: t("ESPECIFICACIONES_COLOR_SMH"),
        },
        {
          id: 8,
          componente: t("COMPONENTE_SOLUBILIDAD_SMH"),
          especificaciones: "338 g/L",
        },
        {
          id: 9,
          componente: t("COMPONENTE_PH_SMH"),
          especificaciones: "5.00-8.00",
        },
        {
          id: 10,
          componente: t("COMPONENTE_GRANULOMETRIA_SMH"),
          especificaciones: "0.10-1 mm",
        },
      ],
    },
    //17
    {
      id: 17,
      nombre: t("NOMBRE_SULFATO_DE_MANGANESO_MONOHIDRATADO"),
      image: sulfato_de_manganeso,
      desc: t("DESC_SULFATO_DE_MANGANESO_MONOHIDRATADO"),
      formula: t("FORMULA_SULFATO_DE_MANGANESO_MONOHIDRATADO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_SULFATO_DE_MANGANESO"),
          especificaciones: "≥ 98.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_MANGANESO"),
          especificaciones: "≥ 31.80%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_AZUFRE_SMM"),
          especificaciones: "≥ 18.00%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_APARIENCIA_SMM"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_SMM"),
        },
        {
          id: 5,
          componente: t("COMPONENTE_COLOR_SMM"),
          especificaciones: t("ESPECIFICACIONES_COLOR_SMM"),
        },
        {
          id: 6,
          componente: t("COMPONENTE_SOLUBILIDAD_SMM"),
          especificaciones: "762 g/L",
        },
        {
          id: 7,
          componente: t("COMPONENTE_PH_SMM"),
          especificaciones: "3.00-5.00",
        },
      ],
    },
    //18
    {
      id: 18,
      nombre: t("NOMBRE_SULFATO_DE_POTASIO"),
      image: sulgato_de_potasio,
      desc: t("DESC_SULFATO_DE_POTASIO"),
      formula: t("FORMULA_SULFATO_DE_POTASIO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_POTASIO_SP"),
          especificaciones: "≥ 50.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_AZUFRE_SP"),
          especificaciones: "≥ 17.50%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_APARIENCIA_SP"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_SP"),
        },
        {
          id: 4,
          componente: t("COMPONENTE_DENSIDAD_SP"),
          especificaciones: "≥ 1.68 g/ml",
        },
        {
          id: 5,
          componente: t("COMPONENTE_COLOR_SP"),
          especificaciones: t("ESPECIFICACIONES_COLOR_SP"),
        },
        {
          id: 6,
          componente: t("COMPONENTE_SOLUBILIDAD_SP"),
          especificaciones: "≥ 99.80%",
        },
        {
          id: 7,
          componente: "pH",
          especificaciones: "2.00-4.00",
        },
      ],
    },
    //19
    {
      id: 19,
      nombre: t("NOMBRE_SULFATO_DE_ZINC_HEPTAHIDRATADO"),
      image: sulfato_de_zinc_heptahidratado,
      desc: t("DESC_SULFATO_DE_ZINC_HEPTAHIDRATADO"),
      formula: t("FORMULA_SULFATO_DE_ZINC_HEPTAHIDRATADO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_SZH"),
          especificaciones: "≥ 98.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_ZINC"),
          especificaciones: "≥ 21.50%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_AZUFRE_SZH"),
          especificaciones: "≥ 10.50%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_APARIENCIA_SZH"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_SZH"),
        },
        {
          id: 5,
          componente: t("COMPONENTE_COLOR_SZH"),
          especificaciones: t("ESPECIFICACIONES_COLOR_SHZ"),
        },
        {
          id: 6,
          componente: t("COMPONENTE_DENSIDAD_SHZ"),
          especificaciones: "3.54 g/cm3",
        },
        {
          id: 7,
          componente: t("COMPONENTE_PH_SHZ"),
          especificaciones: "4.00-6.00",
        },
      ],
    },
    //20
    {
      id: 20,
      nombre: t("NOMBRE_SULFATO_FERROSO_HEPTAHIDRATADO"),
      image: sulfato_ferroso,
      desc: t("DESC_SULFATO_FERROSO_HEPTAHIDRATADO"),
      formula: t("FORMULA_SULFATO_FERROSO_HEPTAHIDRATADO"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "25 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_PUREZA_SFH"),
          especificaciones: "≥ 98.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_HIERRO"),
          especificaciones: "≥ 19.50%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_AZUFRE_SFH"),
          especificaciones: "≥ 11.50%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_APARIENCIA_SFH"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_SFH"),
        },
        {
          id: 5,
          componente: t("COMPONENTE_COLOR_SFH"),
          especificaciones: t("ESPECIFICACIONES_COLOR_SFH"),
        },
        {
          id: 6,
          componente: t("COMPONENTE_DENSIDAD_SFH"),
          especificaciones: "1,898 Kg/m3",
        },
        {
          id: 7,
          componente: t("COMPONENTE_SOLUBILIDAD_SFH"),
          especificaciones: "400 g/L",
        },
        {
          id: 8,
          componente: t("COMPONENTE_PH_SFH"),
          especificaciones: "2.50-4.00",
        },
      ],
    },
    //21
    {
      id: 21,
      nombre: t("Urea"),
      image: ureal,
      desc: t("DESC_UREA"),
      formula: t("FORMULA_UREA"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "45.36 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_NITROGENO_TOTAL_U"),
          especificaciones: "46.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_BEURETH"),
          especificaciones: "≤ 1.00%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_HUMEDAD_U"),
          especificaciones: "≤ 0.50%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_APARIENCIA_U"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_U"),
        },
        {
          id: 5,
          componente: t("COMPONENTE_COLOR_U"),
          especificaciones: t("ESPECIFICACIONES_COLOR_U"),
        },
        {
          id: 6,
          componente: t("COMPONENTE_GRANULOMETRIA_U"),
          especificaciones: "1.00-4.00 mm",
        },
      ],
    },
    //22
    {
      id: 22,
      nombre: t("NOMBRE_PROALGEN"),
      image: proalgen_img,
      desc: t("DESC_PROALGEN"),
      formula: t("FORMULA_PROALGEN"),
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "45.36 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_MATERIA_ORGANICA"),
          especificaciones: "45.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_ACIDO_ALGINICO"),
          especificaciones: "4.00%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_NITROGENO_PRA"),
          especificaciones: "2.00%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_FOSFORO_PRA"),
          especificaciones: "1.00%",
        },
        {
          id: 5,
          componente: t("COMPONENTE_POTASIO_PRA"),
          especificaciones: "2.50%",
        },
        {
          id: 6,
          componente: t("COMPONENTE_CALCIO_PRA"),
          especificaciones: "3.00%",
        },
        {
          id: 7,
          componente: t("COMPONENTE_MAGNESIO_PRA"),
          especificaciones: "3.00%",
        },
        {
          id: 8,
          componente: t("COMPONENTE_AZUFRE_PRA"),
          especificaciones: "4.00%",
        },
        {
          id: 9,
          componente: t("COMPONENTE_HIERRO_PRA"),
          especificaciones: "0.20%",
        },
        {
          id: 10,
          componente: t("COMPONENTE_BORO"),
          especificaciones: "0.002%",
        },
        {
          id: 11,
          componente: t("COMPONENTE_MANGANESO_PRA"),
          especificaciones: "0.0032%",
        },
        {
          id: 12,
          componente: t("COMPONENTE_APARIENCIA_PRA"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_PRA"),
        },
        {
          id: 13,
          componente: t("COMPONENTE_DENSIDAD_PRA"),
          especificaciones: "0.92 g/ml",
        },
        {
          id: 14,
          componente: "pH",
          especificaciones: "7.00-9.00",
        },
        {
          id: 15,
          componente: t("COMPONENTE_SOLUBILIDAD_PRA"),
          especificaciones: t("ESPECIFICACIONES_SOLUBILIDAD_PRA"),
        },
      ],
      Letras: ["P", "R", "O"],
      logo: proalgen_logo_gris,
    },
    //23
    {
      id: 23,
      nombre: t("NOMBRE_HUMINOX_A"),
      image: humonox_a_img,
      desc: t("DESC_HUMINOX_A"),
      formula: "-",
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "20 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: t("COMPONENTE_ACIDO_HUMICO"),
          especificaciones: "≥ 25.00%",
        },
        {
          id: 2,
          componente: t("COMPONENTE_AMINOACIDOS"),
          especificaciones: "≥ 8.00%",
        },
        {
          id: 3,
          componente: t("COMPONENTE_MATERIA_ORGANICA_HX"),
          especificaciones: "≥ 45.00%",
        },
        {
          id: 4,
          componente: t("COMPONENTE_NITROGENO_HX"),
          especificaciones: "12%",
        },
        {
          id: 5,
          componente: t("COMPONENTE_FOSFORO_HX"),
          especificaciones: "0.50%",
        },
        {
          id: 6,
          componente: t("COMPONENTE_POTASIO_HX"),
          especificaciones: "1.50%",
        },
        {
          id: 7,
          componente: t("COMPONENTE_APARIENCIA_HX"),
          especificaciones: t("ESPECIFICACIONES_APARIENCIA_HX"),
        },
        {
          id: 8,
          componente: "pH",
          especificaciones: "3.00-5.00",
        },
        {
          id: 9,
          componente: t("COMPONENTE_DENSIDAD_HX"),
          especificaciones: "0.75 g/ml",
        },
        {
          id: 10,
          componente: t("COMPONENTE_SOLUBILIDAD_HX"),
          especificaciones: t("ESPECIFICACIONES_SOLUBILIDAD_HX"),
        },
        {
          id: 11,
          componente: t("COMPONENTE_GRANULOMETRIA_HX"),
          especificaciones: "< 3.00mm",
        },
      ],
      Letras: ["A", "O"],
      logo: huminox_a_logo_gris,
    },

    {
      id: 24,
      nombre: "FULL HARVEST",
      image: fullharvest,
      desc: "FULL HARVEST es un compuesto especializado con diversas características: es respetuoso con el medio ambiente, regula la microecología del suelo, disminuye los residuos de pesticidas, pasiva los metales pesados, es absorbido de manera eficiente y fácil por los cultivos, regula y mejora efectivamente la calidad y estructura del suelo, rompe la compactación de la tierra, promueve la diferenciación de los botones florales, aumenta la tasa de cuajado de la fruta, promueve la expansión, mejora el peso y color de los frutos, mejora la dureza y contenido de materia sólida y logra estabilidad de almacenamiento. Otras características importantes de este producto corresponden a la promoción del crecimiento del cultivo, mejora del rendimiento y calidad de las siembras, aumento de rendimiento de los cultivos alimentarios en más del 10% con una madurez temprana de entre 5 y 7 días, también favorece al aumento de los rendimientos de los cultivos comerciales en más del 20%, con una madurez temprana de entre 6 y 8 días. Además, mejora la resistencia de los cultivos a las enfermedades y al anidamiento. Controla el crecimiento de patógenos del suelo, reduce las plagas subterráneas. promueve un sistema de raíces bien desarrollado, previene el crecimiento excesivo, induce a la planta a producir fitoalexinas, aumenta la capacidad de resistencia de los cultivos, mejora la estructura de los agregados del suelo, aumenta la cantidad de microorganismos beneficiosos y el contenido de materia orgánica, reduce la densidad aparente del suelo, afloja el suelo, conduce a la retención de agua, aireación, proporciona las mejores condiciones para el crecimiento del cultivo.",
      formula: "-",
      presentaciones: [
        {
          presentacionImg: presentacion_saco,
          presentacionNombre: "20 KG",
        },
      ],
      composicion: [
        {
          id: 1,
          componente: "Material Orgánico",
          especificaciones: "45.25%",
        },
        {
          id: 2,
          componente: "Ácido Algínico",
          especificaciones: "2.60%",
        },
        {
          id: 3,
          componente: "Ácido Húmico",
          especificaciones: "12.50%",
        },
        {
          id: 4,
          componente: "Aminoácidos",
          especificaciones: "4.00%",
        },
        {
          id: 5,
          componente: "Nitrógeno (N)",
          especificaciones: "7.00%",
        },
        {
          id: 6,
          componente: "Fósforo (P)",
          especificaciones: "0.75%",
        },
        {
          id: 7,
          componente: "Potasio (K)",
          especificaciones: "2.00%",
        },
        {
          id: 8,
          componente: "Calcio (Ca)",
          especificaciones: "1.50%",
        },
        {
          id: 9,
          componente: "Magnesio (Mg)",
          especificaciones: "1.50%",
        },
        {
          id: 10,
          componente: "Azufre (S)",
          especificaciones: "2.00%",
        },
        {
          id: 11,
          componente: "Hierro (Fe)",
          especificaciones: "0.010%",
        },
        {
          id: 12,
          componente: "Boro (B)",
          especificaciones: "0.002%",
        },
        {
          id: 13,
          componente: "Manganeso (Mn)",
          especificaciones: "0.0016%",
        },
        {
          id: 14,
          componente: "Apariencia",
          especificaciones: "Granulado Oscuro",
        },
        {
          id: 15,
          componente: "pH",
          especificaciones: "4.00-7.00",
        },
        {
          id: 16,
          componente: "Densidad",
          especificaciones: "0.80 g/ml",
        },
        {
          id: 17,
          componente: "Solubilidad",
          especificaciones: "Poco Soluble (Liberación Lenta)",
        },
        {
          id: 18,
          componente: "Granulometría",
          especificaciones: "< 3.00 mm",
        },
      ],
      Letras: ["F", "U", "L"],
      logo: fullharvest_logo_gris,
    },

    //25
    {
      id: 25,
      nombre: t("article_KIT_NOVATEC"),
      image: img_8331,
      backtroundColor: "green",
      kit_with: "Estuche con",
      caracteristicas: [
        "kit_properties1",
        "kit_properties2",
        "kit_properties3",
        "kit_properties4",
        "kit_properties5",
        "kit_properties6",
        "kit_properties7",
        "kit_properties8",
        "kit_properties9",
        "kit_properties10",
        "kit_properties11",
        "kit_properties12",
        "kit_properties13",
      ],
    },
    //26
    {
      id: 25,
      nombre: t("article_Swatch"),
      image: img_7297,
      desc: t("article_KIT_NOVATEC_DESC"),
      backtroundColor: "green",
      kit_with: "Estuche con",
      caracteristicas: [
        "kit_swath1",
        "kit_swath2",
        "kit_swath3",
        "kit_swath4",
        "kit_swath5",
        "kit_swath6",
        "kit_swath7",
        "kit_swath8",
        "kit_swath9",
        "kit_swath10",
        "kit_swath11",
        "kit_swath12",
      ],
    },
  ];

  const [resultados, setResultados] = useState([]);
  const [backgrounResultado, setBackgroundResultado] = useState(
    "contenedorProducots"
  );
  // contenedorProducotsGreen
  useEffect(() => {
    const resultadosEncontrados = productos.filter((producto) =>
      producto.nombre.toLowerCase().includes(nombre_producto.toLowerCase())
    );
    if (
      resultadosEncontrados[0].backtroundColor &&
      resultadosEncontrados[0].backtroundColor === "green"
    ) {
      setBackgroundResultado("contenedorProducotsGreen");
    }
    setResultados(resultadosEncontrados);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Filtramos la lista de productos según el ID proporcionado
    if (resultados.length !== 0) {
      const resultadosEncontrados = productos.filter(
        (producto) => producto.id === resultados[0].id
      );
      // Establecemos los resultados encontrados en el estado
      setResultados(resultadosEncontrados);
    }
  }, [language]); // Aquí incluimos id_producto como dependencia para que el efecto se ejecute cada vez que cambie

  const highlightName = (name, Letras) => {
    console.log(name, "name");
    console.log(Letras);
    return name.split("").map((char, index) => (
      <span
        key={index}
        className={Letras.includes(char) ? "highlight-PRODUCTO" : ""}
      >
        {char}
      </span>
    ));
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Prueba2 />
      <div className={`container-fluid ${backgrounResultado}`}>
        {resultados.map((producto, index) => (
          <div className="row" key={index}>
            <div className="order-md-0 order-sm-1 order-1 col-md-4 col-12 d-flex justify-content-start align-items-center">
              <div className="container-fluid">
                {producto.formula && (
                  <div className="d-flex justify-content-start align-items-start flex-column">
                    <Fade triggerOnce direction="left">
                      <span className="titulo_formula">
                        {t("title_FORMULA")}
                      </span>
                    </Fade>
                    <Fade triggerOnce direction="left" delay={100}>
                      <span className="formula_producto">
                        {producto.formula}
                      </span>
                    </Fade>
                  </div>
                )}

                {producto.composicion && (
                  <div className="d-flex justify-content-start align-items-start flex-column">
                    <Fade triggerOnce direction="left" delay={125}>
                      <span className="titulo_composicion">
                        {t("COMPOSITION_title")}:
                      </span>
                    </Fade>
                    <Fade triggerOnce direction="left">
                      <div className="tabla-container">
                        <table className="tabla">
                          <thead>
                            <tr className="">
                              <th className="border_cabezera_1">
                                {t("COMPONENTE_title")}
                              </th>
                              <th className="border_cabezera_2">
                                {t("SPECIFICATIONS_title")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {producto.composicion.map((fila) => (
                              <tr key={fila.id}>
                                <td
                                  className={`${
                                    producto.composicion.length === fila.id
                                      ? "componente_ultimo"
                                      : "componente"
                                  }`}
                                >
                                  {fila.componente}
                                </td>
                                <td
                                  className={`${
                                    producto.composicion.length === fila.id
                                      ? "porcentaje_ultimo"
                                      : "porcentaje"
                                  }`}
                                >
                                  {fila.especificaciones}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Fade>
                  </div>
                )}

                {producto.presentaciones && (
                  <div style={{ marginTop: 15, marginBottom: 15 }}>
                    <Fade triggerOnce direction="left" delay={500}>
                      <div>
                        <span className="titulo_presentacion">
                          {t("PRESENTATION")}:
                        </span>
                      </div>
                    </Fade>

                    <div
                      className="row"
                      style={{ marginTop: 15, marginBottom: 15 }}
                    >
                      {producto.presentaciones.map((imagenes, subindex) => (
                        <div className="col-3" key={subindex}>
                          <div
                            className="d-flex justify-content-center align-items-center flex-column"
                            style={{ paddingBottom: 10, paddingTop: 10 }}
                          >
                            <Fade triggerOnce direction="left" delay={200}>
                              {/* <img
                              className="img-fluid"
                              src={imagenes.presentacionImg}
                              alt="producto"
                            /> */}
                              <LazyLoadImage
                                src={imagenes.presentacionImg}
                                effect="blur"
                                alt="producto"
                                className="img-fluid"
                              />
                            </Fade>
                            <Fade triggerOnce direction="left" delay={200}>
                              <span
                                style={{ color: "white", fontWeight: "bold" }}
                              >
                                {imagenes.presentacionNombre}
                              </span>
                            </Fade>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {producto.caracteristicas && (
                  <div style={{ marginTop: 15, marginBottom: 15 }}>
                    <Fade triggerOnce direction="left" delay={500}>
                      <div>
                        <span className="titulo_presentacion">
                          {t("kit_with")}:
                        </span>
                      </div>
                    </Fade>

                    <div
                      className=""
                      style={{ marginTop: 15, marginBottom: 15 }}
                    >
                      {producto.caracteristicas.map((caract, subindex) => (
                        <div key={subindex}>
                          <div
                            className="d-flex justify-content-start align-items-start flex-column"
                            style={{ paddingBottom: 10, paddingTop: 10 }}
                          >
                            <Fade triggerOnce direction="left" delay={200}>
                              <span
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  marginLeft: "1rem",
                                }}
                              >
                                • {t(caract)}
                              </span>
                            </Fade>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="order-md-1 order-sm-0 order-0 col-md-8 col-12 d-flex justify-content-center align-items-center flex-column">
              <Fade triggerOnce duration={2000} direction="left">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {producto.logo === undefined ? null : (
                    <img
                      src={producto.logo}
                      alt={`${producto.nombre} logo`}
                      className="product-logo-PROEX"
                    />
                  )}

                  <h2 className="titulo_producto">
                    {producto.Letras === undefined
                      ? producto.nombre
                      : highlightName(producto.nombre, producto.Letras)}
                  </h2>
                </div>
              </Fade>

              {producto.desc && (
                <Fade triggerOnce duration={2000} direction="right">
                  <div className="container">
                    <p className={`desc_producto ${producto.backtroundColor}`}>
                      {producto.desc}
                    </p>
                  </div>
                </Fade>
              )}

              <Fade
                triggerOnce
                duration={2000}
                direction="left"
                className="d-flex justify-content-center align-items-center"
              >
                <LazyLoadImage
                  src={producto.image}
                  alt="producto"
                  className="img-fluid img_estilo"
                />
              </Fade>

              {/* 
              <Fade
                triggerOnce
                duration={2000}
                direction="left"
                className="d-flex justify-content-center align-items-center"
              > */}
              {/* <img
                  className="img-fluid img_estilo"
                  src={producto.image}
                  alt="producto"
                /> */}
              {/* </Fade> */}
            </div>
            {/* <div className="col-md-12 col-12 d-flex justify-content-center align-items-center"></div> */}
          </div>
        ))}
      </div>
      <Productos />
      <Footer />
    </div>
  );
}
// d-flex justify-content-center align-items-center flex-column
